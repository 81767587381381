import htmx from 'htmx.org';
window.htmx = htmx

import * as Popper from '@popperjs/core'
window.Popper = Popper

import TomSelect from 'tom-select'
window.TomSelect = TomSelect

import * as easepick from '@easepick/bundle'
window.easepick = easepick

import '../sass/app.scss'
import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap
// import Alert from 'bootstrap/js/dist/alert'
import Tooltip from 'bootstrap/js/dist/tooltip'
// import Toast from 'bootstrap/js/dist/toast'
import Popover from 'bootstrap/js/dist/popover'



import '@iconify/iconify'
import 'pwacompat'

import '../assets/js/custom.jsx'

// import { ClassicEditor, Essentials } from 'ckeditor5';

// import 'ckeditor5/ckeditor5.css';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';

// import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
// import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
// import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
// import Link from '@ckeditor/ckeditor5-link/src/link';
// import List from '@ckeditor/ckeditor5-list/src/list';
// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
// import Table from '@ckeditor/ckeditor5-table/src/table';
// import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
// import Undo from '@ckeditor/ckeditor5-undo/src/undo';

// ClassicEditor
//     .create(document.querySelector('#editor'), {
//         plugins: [Essentials, Bold, Italic, Font, Paragraph, Underline,
//             Strikethrough,
//             RemoveFormat,
//             Link,
//             List,
//             BlockQuote,
//             Table,
//             Alignment,
//             Undo],
//         toolbar: [
//             'undo', 'redo', '|', 'bold', 'italic', '|',
//             'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor'
//         ]
//     })
//     .then( /* ... */)
//     .catch( /* ... */);
